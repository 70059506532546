/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TableCell,
} from "@material-ui/core";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import client from "ApiClient";
import MDButton from "components/MDButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ConfirmationAlert from "components/ConfirmationAlert";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import MDInput from "components/MDInput";
import CustomToolbarSelect from "./customToolbarSelect";

// import Edit from "./edit";

/* eslint-disable */
export default function Table(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [items, setItems] = useState([]);
  const [type, setType] = useState(0);
  const [editedItem, setEditedItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState([]);
  const [instructions, setInstruccions] = useState();
  const [successContent, setSuccessContent] = useState("");
  const [errorContent, setErrorContent] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const [openErrorSB, setOpenErrorSB] = useState(false);
  const closeErrorSB = () => setOpenErrorSB(false);
  const [openSuccessSB, setOpenSuccessSB] = useState(false);
  const closeSuccessSB = () => setOpenSuccessSB(false);
  // const handleCloseModal = () => setOpenModal(false);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "senderDomains",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `senderDomains`;
    options.params = {};
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setItems(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleEdit = () => {
    handleOpenModal();
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [props.groupAdded]);

  //SPF : 0
  //DKIM : 1
  //DNS : 3
  //TRACKING:4
  const verify = (item, type) => {
    options.method = "GET";
    options.url = `senderDomains/verify`;
    options.headers = {
      "content-type": "application/json",
    };
    options.params = {
      sed_id: item.sed_id,
      verify_type: type,
    };

    client
      .request(options)
      .then((response) => {
        if (response == "ok") {
          switch (type) {
            case 0:
              setSuccessContent("SPF verified successfully");
              break;
            case 1:
              setSuccessContent("DKIM verified successfully");
              break;
            case 2:
              setSuccessContent("DMARC verified successfully");
              break;
            case 3:
              setSuccessContent("DNS verified successfully");
              break;
            case 4:
              setSuccessContent("Tracking verified successfully");
              break;
          }
          setOpenSuccessSB(true);
        } else {
          switch (type) {
            case 0:
              setErrorContent("SPF verified failed");
              break;
            case 1:
              setErrorContent("DKIM verified failed");
              break;
            case 2:
              setErrorContent("DMARC verified failed");
              break;
            case 3:
              setErrorContent("DNS verified failed");
              break;
            case 4:
              setErrorContent("Tracking verified failed");
              break;
          }
          setOpenErrorSB(true);
        }
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getInstructions = (item, verify_type) => {
    options.method = "GET";
    options.url = `senderDomains/getInstructions`;
    options.headers = {
      "content-type": "application/json",
    };
    options.params = {
      sed_id: item.sed_id,
      sed_type: verify_type,
    };
    setSelectedItem(item);
    setType(verify_type);
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setInstruccions(response);
        setOpenModal(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `senderDomains?sed_id=${items
      .filter((item, index) => {
        return selectedItem.indexOf(index) >= 0 ? true : false;
      })
      .map((b) => b.sed_id)
      .toString()}`;
    options.headers = {
      "content-type": "application/json",
    };
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
        setOpenAlert(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const columns = [
    {
      label: "Domain",
      name: "sed_domain",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Sender domain",
      name: "sed_sendingDomain",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "DNS",
      name: "sed_dns",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return items[dataIndex].sed_dns ? (
            <MDTypography variant="body">
              <TaskAltIcon color="success" />
              DNS verified
            </MDTypography>
          ) : (
            <Button
              onClick={() => verify(items[dataIndex], 3)}
              variant="outlined"
              color="secondary"
            >
              Verify DNS
            </Button>
          );
        },
      },
    },
    {
      label: "DKIM",
      name: "sed_dkim",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return items[dataIndex].sed_dkim ? (
            <MDTypography variant="body">
              <TaskAltIcon color="success" />
              DKIM verified
            </MDTypography>
          ) : (
            <Button
              disabled={!items[dataIndex].sed_dns}
              onClick={() => getInstructions(items[dataIndex], 1)}
              variant="outlined"
              color="secondary"
            >
              Verify DKIM
            </Button>
          );
        },
      },
    },
    {
      label: "SPF",
      name: "sed_spf",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return items[dataIndex].sed_spf ? (
            <MDTypography variant="body">
              <TaskAltIcon color="success" />
              SPF verified
            </MDTypography>
          ) : (
            <Button
              disabled={!items[dataIndex].sed_dns}
              onClick={() => getInstructions(items[dataIndex], 0)}
              variant="outlined"
              color="secondary"
            >
              Verify SPF
            </Button>
          );
        },
      },
    },
    {
      label: "DMARC",
      name: "sed_dmarc",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return items[dataIndex].sed_dmarc ? (
            <MDTypography variant="body">
              <TaskAltIcon color="success" />
              DMARC verified
            </MDTypography>
          ) : (
            <Button
              onClick={() => getInstructions(items[dataIndex], 2)}
              variant="outlined"
              color="secondary"
            >
              Verify DMARC
            </Button>
          );
        },
      },
    },
    {
      label: "Tracking",
      name: "sed_tracking",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return items[dataIndex].sed_tracking ? (
            <MDTypography variant="body">
              <TaskAltIcon color="success" />
              Tracking verified
            </MDTypography>
          ) : (
            <Button
              onClick={() => getInstructions(items[dataIndex], 4)}
              variant="outlined"
              color="secondary"
            >
              Verify Tracking
            </Button>
          );
        },
      },
    },
  ];

  const customToolbar = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any sender domain",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "sender domain/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "single",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    downloadOptions: { filename: "blogs.csv", separator: "," },
    onRowSelectionChange: (curRowSelected, allRowsSelected, rowsSelected) => {
      setSelectedItem(rowsSelected);
    },
    customToolbarSelect: customToolbar,
  };

  return (
    <div>
      <Dialog open={openModal}>
        <DialogTitle>{instructions ? instructions.title : ""}</DialogTitle>
        <DialogContent>
          <MDTypography>Add the following DNS record to your domain's DNS settings:</MDTypography>
          <MDTypography>Record type: {instructions ? instructions.record_type : ""}</MDTypography>
          <MDTypography>Record name: {instructions ? instructions.record_name : ""}</MDTypography>
          <MDTypography>Record content:</MDTypography>

          <MDInput
            type="text"
            fullWidth
            multiline
            value={instructions ? instructions.record_content : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {" "}
                  <CopyToClipboard text={instructions ? instructions.record_content : ""}>
                    <IconButton sx={{ fontWeight: "bold" }} color="primary" aria-label="prompt">
                      <Tooltip id="button-report" title="Copy to clipboard">
                        <ContentCopyIcon />
                      </Tooltip>
                    </IconButton>
                  </CopyToClipboard>{" "}
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDTypography variant="caption" color="error">
            Once the record is added it may take up yo 24 hours to propagate before it can be
            verified successfully.
          </MDTypography>
          <MDButton
            onClick={() => setOpenModal(false)}
            disabled={isLoading}
            variant="outlined"
            color="secondary"
          >
            Close
          </MDButton>
          <MDButton
            onClick={() => verify(selectedItem, type)}
            disabled={isLoading}
            variant="gradient"
            color="success"
          >
            Verify
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color="success"
        icon="success"
        title="Success"
        content={successContent}
        dateTime={""}
        open={openSuccessSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title="Failed"
        content={errorContent}
        dateTime={""}
        open={openErrorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this sender domain?"
      />

      <MDBox mx={2} mt={1} py={3} px={2} borderRadius="lg" variant="gradient">
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDTypography variant="h2">
              Sender domains
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
            <MDBox justifyContent="right">
              <Tooltip title="Create a new sender domain">
                <MDButton variant="gradient" color="secondary" onClick={props.onAdd}>
                  create new
                </MDButton>
              </Tooltip>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox>
        <Grid container p={2}>
          <Grid item xs={12}>
            <div style={{ height: "100%", width: "100%" }}>
              <MUIDataTable data={items} columns={columns} options={tableOptions} />
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}
