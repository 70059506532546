import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { Card, Chip, LinearProgress, TableCell } from "@material-ui/core";
import client from "ApiClient";
import MDTypography from "components/MDTypography";
import MUIDataTable from "mui-datatables";
import PieChart from "examples/Charts/PieChart";
import { useContext, useEffect, useState } from "react";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import moment from "moment";
import parse from "html-react-parser";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import { FeatureFlags } from "context/FeatureFlags";
import MDButton from "components/MDButton";
import Subscription from "./subscription";

/* eslint-disable */
export default function Tasks() {
  const [subscriberSeries, setSubscriberSeries] = useState({});
  const [articlesStatus, setArticlesStatus] = useState({});
  const [dailyEngagement, setDailyEngagement] = useState({});
  const [engagementMetrics, setEngagementMetrics] = useState({});
  const [categoryEngagement, setCategoryEngagement] = useState({});
  const [openSubscription, setOpenSubscription] = useState(false);
  const handleCloseSubscription = () => setOpenSubscription(false);
  // const [landingEngagement, setLandingEngagement] = useState({});
  const [dnsMessage, setDnsMessage] = useState("");
  const [personalities, setPersonalities] = useState({});
  const [statsTop, setStatsTop] = useState([]);
  const [salesStats, setSalesStats] = useState([]);
  const [plan, setPlan] = useState(null);

  const { features } = useContext(FeatureFlags);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "reports",
  };

  const getSubscriberSeries = async () => {
    options.method = "GET";
    options.url = `reports/subscriberSeries`;

    await client
      .request(options)
      .then((response) => {
        setSubscriberSeries(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getArticlesEngagement = async () => {
    options.url = `reports/BLogArticleStats`;

    await client
      .request(options)
      .then((response) => {
        setArticlesStatus(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDailyEngagement = async () => {
    options.url = `reports/DailyEngagement?dateFrom=${moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD")}&dateTo=${moment().format("YYYY-MM-DD")}`;
    await client
      .request(options)
      .then((response) => {
        setDailyEngagement(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const planInformation = async () => {
    options.url = `reports/planInformation`;
    await client
      .request(options)
      .then((response) => {
        setPlan(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPersonalities = async () => {
    options.url = `reports/personalities`;

    await client
      .request(options)
      .then((response) => {
        setPersonalities(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCategoryEngagement = async () => {
    options.url = `reports/CategoryEngagement`;
    await client
      .request(options)
      .then((response) => {
        setCategoryEngagement(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEngagementMetrics = async () => {
    options.url = `reports/engagementMetrics?dateFrom=${moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD")}&dateTo=${moment().format("YYYY-MM-DD")}`;
    await client
      .request(options)
      .then((response) => {
        setEngagementMetrics(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatsTop = async () => {
    const options = {
      method: "GET",
      url: "reports/GetStats",
    };

    await client
      .request(options)
      .then((response) => {
        if (JSON.stringify(response) !== "[{}]");
        setStatsTop(response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const verifyDns = async () => {
    const options = {
      method: "GET",
      url: "reports/DNSCheck",
    };

    await client
      .request(options)
      .then((response) => {
        console.log(response);
        setDnsMessage(response);
        /*
        if (JSON.stringify(response) !== "") setDnsMessage("FAILED DNS VERIFICATION");
        else setDnsMessage("ERROR DNS FAILURE");
        */
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const getSalesStats = async () => {
    const options = {
      method: "GET",
      url: "reports/GetSalesStats",
    };

    await client
      .request(options)
      .then((response) => {
        setSalesStats(response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  useEffect(() => {
    verifyDns();
    getStatsTop();
    getEngagementMetrics();
    getDailyEngagement();
    planInformation();
    if (features.isGooglePayEnabled) {
      getSalesStats();
      getSubscriberSeries();
      getArticlesEngagement();

      getPersonalities();
      // getCategoryEngagement();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getStatsTop();
      getEngagementMetrics();
      getDailyEngagement();
      planInformation();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const getStatus = (plan) => {
    switch (plan.status) {
      case "active":
        return (
          <Chip
            label="Active"
            color="success"
            size="small"
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
          />
        );
      case "trialing":
        return (
          <Chip
            label="Trial"
            color="info"
            size="small"
            style={{ backgroundColor: "#0088F5", color: "#fff" }}
          />
        );
      case "past_due":
        return (
          <Chip
            label="Past due"
            color="warning"
            size="small"
            style={{ backgroundColor: "#ff9800", color: "#fff" }}
          />
        );
      case "canceled":
        return (
          <Chip
            label="Canceled"
            color="error"
            size="small"
            style={{ backgroundColor: "#f44336", color: "#fff" }}
          />
        );
      case "Paused":
        return (
          <Chip
            label="paused"
            color="info"
            size="small"
            style={{ backgroundColor: "#0088F5", color: "#fff" }}
          />
        );
      default:
        return (
          <Chip
            label="Trial"
            color="info"
            size="small"
            style={{ backgroundColor: "#0088F5", color: "#fff" }}
          />
        );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {plan && (
        <Subscription
          openModal={openSubscription}
          closeModal={handleCloseSubscription}
          plan={plan}
        />
      )}
      <MDBox pb={3} pt={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={1}
              py={3}
              px={2}
              bgColor="badgeColors.primary"
              borderRadius="lg"
              variant="gradient"
            >
              <MDTypography variant="h2">Dashboard</MDTypography>
            </MDBox>
            {dnsMessage !== "" && dnsMessage !== undefined && (
              <Grid item xs={12}>
                <MDBox
                  mx={2}
                  mt={1}
                  py={3}
                  px={2}
                  bgColor="badgeColors.error"
                  borderRadius="lg"
                  variant="gradient"
                >
                  <MDTypography variant="h4">DNS Verification</MDTypography>
                  <MDBox p={2} mx={2}>
                    {parse(dnsMessage)}
                  </MDBox>
                </MDBox>
              </Grid>
            )}
            <MDBox>
              <Grid container p={1}>
                {false && (
                  <>
                    <Grid item xs={12} md={3} lg={3} p={1}>
                      <ComplexStatisticsCard
                        color="info"
                        icon="money"
                        title="New sales in the last 24 hours"
                        count={
                          salesStats.total_new_last_24 == null
                            ? "$" + 0
                            : "$" + salesStats.total_new_last_24
                        }
                        percentage={{
                          color:
                            salesStats.total_new_last_24 - salesStats.total_new_diff_24 < 0
                              ? "error"
                              : "success",
                          amount:
                            salesStats.total_new_last_24 == null
                              ? "$" + 0
                              : "$" +
                                Math.abs(
                                  salesStats.total_new_last_24 - salesStats.total_new_diff_24
                                ),
                          label:
                            salesStats.total_new_last_24 - salesStats.total_new_diff_24 < 0
                              ? " less than yesterday"
                              : " more than yesterday",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} p={1}>
                      <ComplexStatisticsCard
                        color="info"
                        icon="money"
                        title="New sales in the last 30 days"
                        count={
                          salesStats.total_landing_last_24 == null
                            ? "$" + 0
                            : "$" + salesStats.total_landing_last_24
                        }
                        percentage={{
                          color:
                            salesStats.total_landing_last_24 - salesStats.total_landing_diff_24 < 0
                              ? "error"
                              : "success",
                          amount:
                            salesStats.total_landing_last_24 == null
                              ? "$" + 0
                              : "$" +
                                Math.abs(
                                  salesStats.total_landing_last_24 -
                                    salesStats.total_landing_diff_24
                                ),
                          label:
                            salesStats.total_landing_last_24 - salesStats.total_landing_diff_24 < 0
                              ? " less than last month"
                              : " more than last month",
                        }}
                      />
                    </Grid>
                  </>
                )}
                {plan && (
                  <Grid item xs={12} md={12} lg={12} p={1} mb={2} pb={2}>
                    <Card>
                      <MDBox pb={2} px={2} pt={2}>
                        <Grid container>
                          <Grid item xs={10}>
                            <MDTypography
                              variant="h4"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              Subscription plan
                            </MDTypography>
                            <MDTypography variant="subtitle2" color="text" fontWeight="regular">
                              Cost ${plan.cost / 100} / {plan.frequency}
                            </MDTypography>
                          </Grid>
                          <Grid item xs={2} style={{ width: "100%", textAlign: "right" }}>
                            {getStatus(plan)}
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox pb={2} px={2}>
                        <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
                          Current Plan
                        </MDTypography>
                        <MDTypography variant="subtitle2" color="text" fontWeight="regular">
                          {plan.totalContacts} / {plan.contactsLimit} subscribers
                        </MDTypography>
                        <LinearProgress
                          variant="determinate"
                          value={(plan.totalContacts * 100) / plan.contactsLimit}
                        />
                      </MDBox>
                      <MDBox pb={2} px={2}>
                        {plan.status === "active" && (
                          <MDTypography variant="subtitle" color="text" fontWeight="regular">
                            Your plan will renew at{" "}
                            {moment(plan.nextBillingDate).format("MMM Do YY")}
                          </MDTypography>
                        )}
                        {plan.status === "past_due" && (
                          <MDTypography variant="subtitle" color="text" fontWeight="regular">
                            Fail to billing your subscription. Please complete your payment
                            <a
                              href={plan.hostedInvoiceUrl}
                              target="_blank"
                              variant="button"
                              color="info"
                              fontWeight="medium"
                              textGradient
                            >
                              {" "}
                              here
                            </a>
                          </MDTypography>
                        )}
                      </MDBox>
                      <MDBox pb={2} px={2}>
                        {plan.remainingContacts < 0 && (
                          <MDTypography variant="subtitle" color="text" fontWeight="regular">
                            Your current subscriptors exceed the limit.{" "}
                            <MDButton
                              variant="gradient"
                              color="success"
                              onClick={() => setOpenSubscription(true)}
                              style={{ marginRight: 10 }}
                            >
                              Please upgrade your plan
                            </MDButton>
                          </MDTypography>
                        )}
                      </MDBox>
                    </Card>
                  </Grid>
                )}
                <Grid item xs={12} md={6} lg={6} p={1}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="subscribers"
                    title="New subscribers in the last 24 hours"
                    count={statsTop.total_new_last_24 == null ? 0 : statsTop.total_new_last_24}
                    percentage={{
                      color:
                        statsTop.total_new_last_24 - statsTop.total_new_diff_24 < 0
                          ? "error"
                          : "success",
                      amount:
                        statsTop.total_new_last_24 == null
                          ? 0
                          : Math.abs(statsTop.total_new_last_24 - statsTop.total_new_diff_24),
                      label:
                        statsTop.total_new_last_24 - statsTop.total_new_diff_24 < 0
                          ? " less than yesterday"
                          : " more than yesterday",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} p={1}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="landing"
                    title="Clicks events in the last 24 hours"
                    count={
                      statsTop.total_landing_last_24 == null ? 0 : statsTop.total_landing_last_24
                    }
                    percentage={{
                      color:
                        statsTop.total_landing_last_24 - statsTop.total_landing_diff_24 < 0
                          ? "error"
                          : "success",
                      amount:
                        statsTop.total_landing_last_24 == null
                          ? 0
                          : Math.abs(
                              statsTop.total_landing_last_24 - statsTop.total_landing_diff_24
                            ),
                      label:
                        statsTop.total_landing_last_24 - statsTop.total_landing_diff_24 < 0
                          ? " less than yesterday"
                          : " more than yesterday",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container p={1}>
                {false && (
                  <>
                    <Grid item xs={12} md={6} lg={6} p={1}>
                      <HorizontalBarChart
                        title="Subscribers in series"
                        description="Total subscribers currently on each serie"
                        chart={subscriberSeries}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} p={1}>
                      <HorizontalBarChart
                        title="Articles"
                        chart={articlesStatus}
                        description="Top 10 most engaging blog articles"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} p={1}>
                      <PieChart
                        title="Personalities"
                        chart={personalities}
                        description="Subscribers count per personalities"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} p={1}>
                      <PieChart
                        title="Categories"
                        chart={categoryEngagement}
                        description="Most engaging categories"
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={6} lg={6} p={1}>
                  <DefaultLineChart
                    title="Daily engagement"
                    description="Total engagement count by date"
                    chart={dailyEngagement}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} p={1}>
                  <DefaultLineChart
                    title="Engagement metrics"
                    description="Total events by date"
                    chart={engagementMetrics}
                  />
                </Grid>
                <div style={{ height: "100%", width: "100%" }} hidden>
                  <MUIDataTable />
                </div>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
